<template>
  <div class="payments page">
    <div class="content">
      <div class="page_header">
        <h1>Платежи</h1>
        <el-button size="mini" @click="create" type="primary" icon="el-icon-plus">Платеж</el-button>
      </div>
      <div id="filter">
        <div class="query ib">
          <el-input
              v-model="filter.query"
              clearable
              size="mini"
              @keyup.enter.native="changeFilter"
              @change="changeFilter"
              placeholder="Поиск по номеру телефона, имени клиента и т.д."
          ></el-input>
        </div>
        <div class="visibility ib">
          <el-radio-group v-model="filter.visibility" size="mini" @change="changeFilter">
            <el-radio-button label="my">Только мои</el-radio-button>
            <el-radio-button label="office">Мой офис</el-radio-button>
            <el-radio-button label="all">Все обращения</el-radio-button>
          </el-radio-group>
        </div>
        <div class="date_range ib">
          <el-date-picker
              clearable
              v-model="filter.dateRange"
              type="daterange"
              align="left"
              size="mini"
              unlink-panels
              format="dd.MM.yyyy"
              value-format="dd.MM.yyyy"
              start-placeholder="Дата от"
              end-placeholder="до"
              :picker-options="datePickerOptions"
              @change="changeFilter"></el-date-picker>
        </div>
      </div>
      <div class="box" v-loading="loading">
        <div class="grid" v-if="payments.length">
          <div
              class="request grid_row"
              v-for="payment in payments" :key="payment.id">
            <el-row :gutter="0">
              <el-col :span="4">
                <div class="client">
                  <el-link
                      @click.stop.prevent
                      :underline="false"
                      class="client_link"
                      icon="">
                    <!-- <el-avatar size="small"> {{request.client.name[0] }} </el-avatar> -->
                    <span class="client_name">Платеж №{{ payment.number }}</span> от
                    {{ moment(payment.created_at).format("DD.MM.YYYY HH:mm") }}
                  </el-link>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="client">
                  <el-link
                      @click.stop.prevent
                      :underline="false"
                      class="client_link"
                      icon="el-icon-user">
                    <!-- <el-avatar size="small"> {{request.client.name[0] }} </el-avatar> -->
                    <span class="client_name">Клиент: {{ payment.client.name }}</span>
                  </el-link>
                </div>
              </el-col>
              <el-col :span="4">
                <el-tag
                    v-if="payment.order"
                    size="mini"
                    class="order"
                    :class="{ notapplicable: !payment.order.applicable }">
                  <span class="title">Заказ №{{ payment.order.number }}</span>
                  -
                  <strong class="price">{{ (payment.order.sum_wd) | price }}р</strong>
                </el-tag>
              </el-col>
              <el-col :span="6">
                {{ payment.comment }}
                {{ payment.purpose }}
              </el-col>
            </el-row>
          </div>
        </div>
        <div class="empty" v-else>
          <p>Нет данных для отображения</p>
        </div>
      </div>
      <div class="pagination">
        <el-pagination
            layout="prev, pager, next"
            :total="paymentsSize"
            :page-size="filter.limit"
            :current-page="currentPage"
            :hide-on-single-page="true"
            @current-change="paginateChange"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import {actionTypes as paymentsActions} from "@/store/modules/payments";

import price from "@/filters/price";

export default {
  name: "Payments",
  data() {
    return {
      filter: {
        limit: this.$route.query.limit ? Number(this.$route.query.limit) : 50,
        offset: this.$route.query.offset ? Number(this.$route.query.offset) : 0,
        query: this.$route.query.query ? this.$route.query.query : '',
        visibility: this.$route.query.visibility ? this.$route.query.visibility : 'all',
        dateRange: [],
      },
      datePickerOptions: {
        firstDayOfWeek: 1,
        shortcuts: [
          {
            text: "Неделя",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Месяц",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "3 месяца",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ]
      }
    }
  },
  computed: {
    ...mapState({
      payments: state => state.payments.list,
      paymentsSize: state => state.payments.size,
      loading: state => state.payments.loading,
      submitting: state => state.payments.submitting,
    }),
    currentPage() {
      return ((this.filter.offset + this.filter.limit) / this.filter.limit)
    },
  },
  methods: {
    create() {

    },
    loadPayments() {
      this.$store.dispatch(paymentsActions.getAll, this.filter)
    },
    paginateChange(page) {
      this.filter.offset = (page - 1) * this.filter.limit;
      this.changeRoute();
      this.loadPayments();
    },
    changeFilter() {
      this.filter.offset = 0
      this.changeRoute()
      this.loadPayments()
    },
    changeRoute() {
      this.$router
          .push({path: 'payments', query: this.filter})
          .catch((e) => {
            console.log('route not changed')
          })
    },
    openPayment(id) {

    },
    clear() {
      this.$store.dispatch(paymentsActions.clear)
    }
  },
  filters: {
    price
  },
  created() {
    document.title = this.$route.meta.title ? this.$route.meta.title : 'GlassERP'
  },
  mounted() {
    this.loadPayments()
  },
  beforeDestroy() {
    this.clear()
  },
}
</script>
